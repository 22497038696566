/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
html {
  overflow-y: scroll;
}
@font-face {
  font-family: sofia-pro;
  src: url("./fonts/sofia-pro/sofiapro-light.otf");
}
@font-face {
  font-family: sofia-pro-bold;
  src: url("./fonts/sofia-pro/sofiapro-bold.ttf");
}
@font-face {
  font-family: chronicle;
  src: url("./fonts/chronicle/ChronicleSemibold.otf");
}
@font-face {
  font-family: chronicle;
  src: url("./fonts/chronicle/Chronicle\ Display\ Roman.otf");
  font-weight: 400;
}

@font-face {
  font-family: chronicle;
  src: url("./fonts/chronicle/ChronicleBlack.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Chronicle Display";
  src: url("./fonts/chronicle/ChronicleSemibold.otf");
}
@font-face {
  font-family: "Chronicle Display";
  src: url("./fonts/chronicle/Chronicle\ Display\ Roman.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Chronicle Display";
  src: url("./fonts/chronicle/ChronicleBlack.otf");
  font-weight: 500;
}

@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: poppins;
  src: url("./fonts/Poppins/Poppins-Medium.ttf");
  font-weight: 500;
}
.fw-thin {
  font-weight: 100;
}
.fw-extralight {
  font-weight: 200;
}
.fw-normal {
  font-weight: 400 !important;
}

.ff-sofia-pro {
  font-family: sofia-pro;
}

.ff-chronicle {
  font-family: chronicle;
}
.ff-poppins {
  font-family: poppins;
}

:root {
  --color-1: #428bca;
  --color-2: #f2682b;
  --color-2-hover: #d65a24;
  --text-color-2: rgb(86, 86, 86);
  --text-color-3: #5d5d5d;
}
.main-logo {
  font-family: chronicle;
  font-weight: 500;
}
.custom.nav-link {
  font-family: poppins !important;
  color: rgb(128, 128, 128) !important;
  font-weight: 500;
}

.custom.nav-link:hover {
  color: var(--color-1) !important;
}

.navbar-brand h4 {
  color: var(--color-2);
  font-weight: 600 !important;
  font-size: 1.5rem;
}

.btn.btn-2 {
  background-color: var(--color-2);
  color: white;
  font-family: poppins;
  font-weight: 500;
}
.btn.btn-2:hover {
  background-color: var(--color-2-hover);
}

nav {
  position: relative;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.navbar-toggler {
  background: #ededed !important;
  border: unset;
  outline: none !important;
}
.navbar-toggler:hover {
  background: #e0e0e0 !important;
}
.navbar-light .navbar-nav .nav-link {
  padding: 15px;
}

.section-heading {
  font-size: 40px;
  font-family: chronicle;
  font-weight: 400 !important;
}

.social-icon {
  font-size: 19px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 3em;
  width: 3em;
  border: 2px solid;
  align-items: center;
  text-decoration: none !important;
  transition: 0.3s;
}
.social-icon:hover {
  transform: scale(1.2);
}
.social-icon.facebook {
  color: white;
  background-color: #3a589d;
  border-color: #3a589d;
}
/* .social-icon.facebook:hover {
  color: white;
  background-color: #3a589d;
  border-color: #3a589d;
} */

.social-icon.twitter {
  color: white;
  background-color: #55abf4;
  border-color: #55abf4;
}

.social-icon.linkedin {
  color: white;
  background-color: #0477b5;
  border-color: #0477b5;
}

.social-icon.instagram {
  color: white;
  background-color: #e1306c;
  border-color: #e1306c;
}
footer {
  font-family: sofia-pro;
}
footer a,
footer h4 {
  color: #9e9e9e;
}
footer ul.list-unstyled {
  margin-bottom: 0;
}
footer ul a.footer-nav-link {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 16px;
}
footer ul a.footer-nav-link:hover {
  color: #e0e0e0;
}
footer .social-icon {
  color: #212121 !important;
  background-color: white !important;
  border: none !important;
  height: 1.8em !important;
  width: 1.8em !important;
  font-size: 17px !important;
  margin-left: 6px;
  margin-right: 6px;
}
.btn-theme-primary {
  color: white !important;
  background-color: #428bca !important;
}
.btn-theme-primary:hover {
  color: white !important;
  background-color: #3b79af !important;
}

.blog-detail-title {
  font-weight: 400 !important;
}

.card {
  display: block !important;
  text-decoration: none !important;
}
.card.hover-effect-1 {
  border: none !important;
}
.card.hover-effect-1 > * {
  z-index: 1;
  position: relative;
}
.card.hover-effect-1:hover {
  z-index: 1;
}

.card.hover-effect-1 img {
  max-width: 100% !important;
  height: auto !important;
  max-height: inherit !important;
  margin: 0 !important;
  transition: clip-path 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-clip-path 0.17s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-clip-path: inset(8% 4% 0 4% round 4px 4px 4px 4px);
  clip-path: inset(8% 4% 0 4% round 4px 4px 4px 4px);
}

.card.hover-effect-1:hover img {
  transition: clip-path 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-clip-path 0.17s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-clip-path: inset(0 0 0 0 round 4px 4px 0 0);
  clip-path: inset(0 0 0 0 round 4px 4px 0 0);
  position: relative;
  backface-visibility: hidden;
}
.card.hover-effect-1:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  opacity: 0;
  border-radius: 4px;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.17s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 0;
  transform: scale(0.92);
  backface-visibility: hidden;
  will-change: opacity, transform, transition;
}

.card.hover-effect-1:hover:after {
  opacity: 1;
  transform: scale(1);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card p {
  color: var(--text-color-3) !important;
}
.card a {
  text-decoration: none !important;

  color: #222222 !important;
}
.card .read-more {
  text-decoration: none !important;
  font-family: sofia-pro-bold;
  font-family: 700;
  font-size: 12px !important;
}
.card a:not(.btn):hover {
  color: #4d6db8 !important;
}
a.read-more {
  color: #2e2e2e !important;
}
a.read-more:not(.btn):hover {
  color: #4d6db8 !important;
}

.card .btn-light {
  font-weight: 400;
}
.card .btn-light:hover {
  color: white !important;
  background-color: #0477b5 !important;
}

.owl-left-btn,
.owl-right-btn {
  cursor: pointer;
  font-size: 27px;
  height: 40px;
  width: 40px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #dadada;
  align-items: center;
  justify-content: center;
  color: #0477b5;
  position: absolute;
  display: flex;
  transition: 0.3s;
}
.owl-left-btn:hover,
.owl-right-btn:hover {
  background: #ffffff;
}

.owl-left-btn {
  left: -40px;
}
.owl-right-btn {
  right: -40px;
}
@media (max-width: 762px) {
  nav .navbar-collapse {
    position: absolute;
    top: 100%;
    background: rgb(236, 236, 236);
    z-index: 3;
    left: 50%;
    width: 95%;
    padding: 4px 24px;
    z-index: 106;
    transform: translateX(-50%);
  }
}
@media (min-width: 462px) {
  .card img {
    height: 280px !important;
  }
}
@media (min-width: 580px) {
  .card img {
    height: 200px !important;
  }
}
@media (min-width: 762px) {
  .section-heading {
    font-size: 54px;
  }
  .navbar-brand h4 {
    font-size: 2.5rem;
  }
  .card img {
    height: 250px !important;
  }
}

@media (min-width: 992px) {
  .card img {
    height: 230px !important;
  }
  .card.sm img {
    height: 175px !important;
  }
  .card.xl img {
    height: 230px !important;
  }
  .blog-detail-title {
    font-size: 54px;
  }
}

@media (min-width: 1235px) {
  .owl-left-btn {
    left: -60px;
  }
  .owl-right-btn {
    right: -60px;
  }
}
.dropdown-menu {
  top: 93% !important;
}
.dropdown-item {
  font-family: poppins;
  padding: 0.5rem 0.8rem !important;
  text-decoration: none !important;
  font-weight: 500;
  color: #6e6e6e;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #000000 !important;
  background-color: #f5f5f5 !important;
}

.card-text {
  font-family: "sofia-pro" !important;
}
.myEditor div[style*="text-align:none"] {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer;
}
.share {
  font-family: "sofia-pro";
  color: white !important;
  padding: 4px 11px !important;
  font-size: 13px;
}
.share.twitter {
  background-color: hsl(206, 82%, 63%);
}
.share.twitter:hover {
  background-color: hsl(206, 82%, 60%);
}

.share.facebook {
  background-color: hsl(221, 44%, 41%);
}
.share.facebook:hover {
  background-color: hsl(221, 44%, 37%);
}

.share.linkedin {
  background-color: hsl(201, 100%, 35%);
}
.share.linkedin:hover {
  background-color: hsl(201, 100%, 30%);
}
