/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
html {
  overflow-y: scroll;
}
@font-face {
  font-family: sofia-pro;
  src: url(/static/media/sofiapro-light.6bf30b6e.otf);
}
@font-face {
  font-family: sofia-pro-bold;
  src: url(/static/media/sofiapro-bold.7784107f.ttf);
}
@font-face {
  font-family: chronicle;
  src: url(/static/media/ChronicleSemibold.9f9befc3.otf);
}
@font-face {
  font-family: chronicle;
  src: url("/static/media/Chronicle Display Roman.db579431.otf");
  font-weight: 400;
}

@font-face {
  font-family: chronicle;
  src: url(/static/media/ChronicleBlack.e883b91b.otf);
  font-weight: 500;
}

@font-face {
  font-family: "Chronicle Display";
  src: url(/static/media/ChronicleSemibold.9f9befc3.otf);
}
@font-face {
  font-family: "Chronicle Display";
  src: url("/static/media/Chronicle Display Roman.db579431.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Chronicle Display";
  src: url(/static/media/ChronicleBlack.e883b91b.otf);
  font-weight: 500;
}

@font-face {
  font-family: poppins;
  src: url(/static/media/Poppins-ExtraLight.6f8391bb.ttf);
  font-weight: 200;
}

@font-face {
  font-family: poppins;
  src: url(/static/media/Poppins-Light.fcc40ae9.ttf);
  font-weight: 300;
}

@font-face {
  font-family: poppins;
  src: url(/static/media/Poppins-Regular.093ee89b.ttf);
  font-weight: 400;
}
@font-face {
  font-family: poppins;
  src: url(/static/media/Poppins-Medium.bf59c687.ttf);
  font-weight: 500;
}
.fw-thin {
  font-weight: 100;
}
.fw-extralight {
  font-weight: 200;
}
.fw-normal {
  font-weight: 400 !important;
}

.ff-sofia-pro {
  font-family: sofia-pro;
}

.ff-chronicle {
  font-family: chronicle;
}
.ff-poppins {
  font-family: poppins;
}

:root {
  --color-1: #428bca;
  --color-2: #f2682b;
  --color-2-hover: #d65a24;
  --text-color-2: rgb(86, 86, 86);
  --text-color-3: #5d5d5d;
}
.main-logo {
  font-family: chronicle;
  font-weight: 500;
}
.custom.nav-link {
  font-family: poppins !important;
  color: rgb(128, 128, 128) !important;
  font-weight: 500;
}

.custom.nav-link:hover {
  color: #428bca !important;
  color: var(--color-1) !important;
}

.navbar-brand h4 {
  color: #f2682b;
  color: var(--color-2);
  font-weight: 600 !important;
  font-size: 1.5rem;
}

.btn.btn-2 {
  background-color: #f2682b;
  background-color: var(--color-2);
  color: white;
  font-family: poppins;
  font-weight: 500;
}
.btn.btn-2:hover {
  background-color: #d65a24;
  background-color: var(--color-2-hover);
}

nav {
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.navbar-toggler {
  background: #ededed !important;
  border: unset;
  outline: none !important;
}
.navbar-toggler:hover {
  background: #e0e0e0 !important;
}
.navbar-light .navbar-nav .nav-link {
  padding: 15px;
}

.section-heading {
  font-size: 40px;
  font-family: chronicle;
  font-weight: 400 !important;
}

.social-icon {
  font-size: 19px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 3em;
  width: 3em;
  border: 2px solid;
  align-items: center;
  text-decoration: none !important;
  transition: 0.3s;
}
.social-icon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.social-icon.facebook {
  color: white;
  background-color: #3a589d;
  border-color: #3a589d;
}
/* .social-icon.facebook:hover {
  color: white;
  background-color: #3a589d;
  border-color: #3a589d;
} */

.social-icon.twitter {
  color: white;
  background-color: #55abf4;
  border-color: #55abf4;
}

.social-icon.linkedin {
  color: white;
  background-color: #0477b5;
  border-color: #0477b5;
}

.social-icon.instagram {
  color: white;
  background-color: #e1306c;
  border-color: #e1306c;
}
footer {
  font-family: sofia-pro;
}
footer a,
footer h4 {
  color: #9e9e9e;
}
footer ul.list-unstyled {
  margin-bottom: 0;
}
footer ul a.footer-nav-link {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 16px;
}
footer ul a.footer-nav-link:hover {
  color: #e0e0e0;
}
footer .social-icon {
  color: #212121 !important;
  background-color: white !important;
  border: none !important;
  height: 1.8em !important;
  width: 1.8em !important;
  font-size: 17px !important;
  margin-left: 6px;
  margin-right: 6px;
}
.btn-theme-primary {
  color: white !important;
  background-color: #428bca !important;
}
.btn-theme-primary:hover {
  color: white !important;
  background-color: #3b79af !important;
}

.blog-detail-title {
  font-weight: 400 !important;
}

.card {
  display: block !important;
  text-decoration: none !important;
}
.card.hover-effect-1 {
  border: none !important;
}
.card.hover-effect-1 > * {
  z-index: 1;
  position: relative;
}
.card.hover-effect-1:hover {
  z-index: 1;
}

.card.hover-effect-1 img {
  max-width: 100% !important;
  height: auto !important;
  max-height: inherit !important;
  margin: 0 !important;
  transition: clip-path 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-clip-path 0.17s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-clip-path: inset(8% 4% 0 4% round 4px 4px 4px 4px);
  clip-path: inset(8% 4% 0 4% round 4px 4px 4px 4px);
}

.card.hover-effect-1:hover img {
  transition: clip-path 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-clip-path 0.17s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-clip-path: inset(0 0 0 0 round 4px 4px 0 0);
  clip-path: inset(0 0 0 0 round 4px 4px 0 0);
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.card.hover-effect-1:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  opacity: 0;
  border-radius: 4px;
  transition: opacity 0.17s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.17s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.17s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 0;
  -webkit-transform: scale(0.92);
          transform: scale(0.92);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: opacity, transform, transition;
}

.card.hover-effect-1:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 300ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card p {
  color: #5d5d5d !important;
  color: var(--text-color-3) !important;
}
.card a {
  text-decoration: none !important;

  color: #222222 !important;
}
.card .read-more {
  text-decoration: none !important;
  font-family: sofia-pro-bold;
  font-family: 700;
  font-size: 12px !important;
}
.card a:not(.btn):hover {
  color: #4d6db8 !important;
}
a.read-more {
  color: #2e2e2e !important;
}
a.read-more:not(.btn):hover {
  color: #4d6db8 !important;
}

.card .btn-light {
  font-weight: 400;
}
.card .btn-light:hover {
  color: white !important;
  background-color: #0477b5 !important;
}

.owl-left-btn,
.owl-right-btn {
  cursor: pointer;
  font-size: 27px;
  height: 40px;
  width: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 50%;
  background: #dadada;
  align-items: center;
  justify-content: center;
  color: #0477b5;
  position: absolute;
  display: flex;
  transition: 0.3s;
}
.owl-left-btn:hover,
.owl-right-btn:hover {
  background: #ffffff;
}

.owl-left-btn {
  left: -40px;
}
.owl-right-btn {
  right: -40px;
}
@media (max-width: 762px) {
  nav .navbar-collapse {
    position: absolute;
    top: 100%;
    background: rgb(236, 236, 236);
    z-index: 3;
    left: 50%;
    width: 95%;
    padding: 4px 24px;
    z-index: 106;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@media (min-width: 462px) {
  .card img {
    height: 280px !important;
  }
}
@media (min-width: 580px) {
  .card img {
    height: 200px !important;
  }
}
@media (min-width: 762px) {
  .section-heading {
    font-size: 54px;
  }
  .navbar-brand h4 {
    font-size: 2.5rem;
  }
  .card img {
    height: 250px !important;
  }
}

@media (min-width: 992px) {
  .card img {
    height: 230px !important;
  }
  .card.sm img {
    height: 175px !important;
  }
  .card.xl img {
    height: 230px !important;
  }
  .blog-detail-title {
    font-size: 54px;
  }
}

@media (min-width: 1235px) {
  .owl-left-btn {
    left: -60px;
  }
  .owl-right-btn {
    right: -60px;
  }
}
.dropdown-menu {
  top: 93% !important;
}
.dropdown-item {
  font-family: poppins;
  padding: 0.5rem 0.8rem !important;
  text-decoration: none !important;
  font-weight: 500;
  color: #6e6e6e;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #000000 !important;
  background-color: #f5f5f5 !important;
}

.card-text {
  font-family: "sofia-pro" !important;
}
.myEditor div[style*="text-align:none"] {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer;
}
.share {
  font-family: "sofia-pro";
  color: white !important;
  padding: 4px 11px !important;
  font-size: 13px;
}
.share.twitter {
  background-color: hsl(206, 82%, 63%);
}
.share.twitter:hover {
  background-color: hsl(206, 82%, 60%);
}

.share.facebook {
  background-color: hsl(221, 44%, 41%);
}
.share.facebook:hover {
  background-color: hsl(221, 44%, 37%);
}

.share.linkedin {
  background-color: hsl(201, 100%, 35%);
}
.share.linkedin:hover {
  background-color: hsl(201, 100%, 30%);
}

.sticky-container{
    padding:0px;
    margin:0px;
    position:fixed;
    right:-105px;
    top:280px;
    width:210px;
    z-index: 1100;
}
.sticky li{
    list-style-type:none;
    border-radius: 4px;
    background-color: rgb(134, 200, 222);
    color:#efefef;
    height:75px;
    padding:16px;
    margin:0px 0px 1px 0px;
    transition:all 0.25s ease-in-out;
    cursor:pointer;
}
.sticky li:hover{
    margin-left:-85px;
    border-radius: 40px;
 
}
.sticky li p:hover{
    border-left: 2px solid white;
    height:60px;
    position: absolute;
    
    left: 10%;
    margin-left: -2px;
    top: 2px;
  }
.sticky li img{
    float:left;
    margin:5px 4px;
    margin-right:5px;
    background-color:rgb(134, 200, 222);
}
.sticky li p{
    padding-top:28px;
    margin:0px;
    line-height:16px;
    font-size:14px;
}
.sticky li p a{
    text-decoration:none;
    color:white;
}
.sticky li p a:hover{
   
    color:white;
}




.heading {
    text-align: center;
    color: #454343;
    font-size: 30px;
    font-weight: 700;
    position: relative;
    margin-bottom: 70px;
    text-transform: uppercase;
    z-index: 999;
}
.white-heading{
    color: #ffffff;
}
.heading:after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    height: 40px;
    width: 180px;
    border-radius: 4px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: url();
    background-repeat: no-repeat;
    background-position: center;
}
.white-heading:after {
    background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png);
    background-repeat: no-repeat;
    background-position: center;
}

.heading span {
    font-size: 18px;
    display: block;
    font-weight: 500;
}
.white-heading span {
    color: #ffffff;
}
/*-----Testimonial-------*/

.testimonial:after {
    position: absolute;
    top: -0 !important;
    left: 0;
    content: " ";
    background: url();
    background-size: 100% 100px;
    width: 100%;
    height: 100px;
    float: left;
    z-index: 99;
}

.testimonial {
    min-height: 375px;
    position: relative;
    background: url(https://i.ibb.co/PTJDkgb/testimonials.jpg);
    padding-top: 50px;
    padding-bottom: 50px;
    background-position: center;
        background-size: cover;
}
#testimonial4 .carousel-inner:hover{
  cursor: -webkit-grab;
}
#testimonial4 .carousel-inner:active{
  cursor: -webkit-grabbing;
}
#testimonial4 .carousel-inner .item{
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators{
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.testimonial4_indicators .carousel-indicators li{
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators .active{
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  background-color: #9dd3af;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar{
  height: 3px;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb{
  background: #eeeeee;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control{
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  border-radius: 100%;
  box-shadow: none;
  transition: all 0.6s cubic-bezier(0.3,1,0,1);
}
.testimonial4_control_button .carousel-control.left{
  left: 7%;
  top: 50%;
  right: auto;
}
.testimonial4_control_button .carousel-control.right{
  right: 7%;
  top: 50%;
  left: auto;
}
.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover{
  color: #000;
  background: #fff;
  border: 2px solid #fff;
}

.testimonial4_header{
  top: 0;
  left: 0;
  bottom: 0;
  width: 550px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}
.testimonial4_header h4{
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial4_slide{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}
.testimonial4_slide img {
    top: 0;
    left: 0;
    right: 0;
    width: 136px;
    height: 136px;
    margin: auto;
    display: block;
    color: #f2f2f2;
    font-size: 18px;
    line-height: 46px;
    text-align: center;
    position: relative;
    border-radius: 50%;
    box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
    -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}
.testimonial4_slide p {
    color: #ffffff;
    font-size: 20px;
    line-height: 1.4;
    margin: 40px 0 20px 0;
}
.testimonial4_slide h4 {
  color: #ffffff;
  font-size: 22px;
}

.testimonial .carousel {
	padding-bottom:50px;
}
.testimonial .carousel-control-next-icon, .testimonial .carousel-control-prev-icon {
    width: 35px;
    height: 35px;
}
/* ------testimonial  close-------*/
html, body { padding: 0; margin: 0; }
.image-grid div {
  width: 25%;
  height: 240px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  font-weight: normal;
  font-size: 12px;
}

.image01 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-01.jpg'); }
.image02 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-02.jpg'); }
.image03 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-03.jpg'); }
.image04 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-04.jpg'); }
.image05 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-05.jpg'); }
.image06 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-06.jpg'); }
.image07 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-07.jpg'); }
.image08 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-08.jpg'); }
.image09 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-09.jpg'); }
.image10 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-10.jpg'); }
.image11 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-11.jpg'); }
.image12 { background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/409269/food-drink-12.jpg'); }

@media screen and (max-width: 400px) {
  .image-grid {
    flex-direction: column;
    flex-wrap: no-wrap;
  }
  .image-grid div { width: 100%; }
}
.nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom:0;
    border-right: 1px solid #ddd;
    padding-right: 15px;
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background: white;
}

.nav-tabs .nav-link.active {
    color: white;
    background-color:#B49C73 !important;
    border-color: transparent !important;
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0rem!important;
    border-top-right-radius: 0rem!important;
}
.tab-content>.active {
    display: block;
    background: white;
    min-height: 200px;
    
    
}
.nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom: 0;
    border-right: 1px solid transparent;
    padding-right: 15px;
}

hr.solid {
    border-top: 3px solid #bbb;
  }

.nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom:0;
    border-right: 1px solid #ddd;
    padding-right: 15px;
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background: white;
}

.nav-tabs .nav-link.active {
    color: white;
    background-color:#B49C73 !important;
    border-color: transparent !important;
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0rem!important;
    border-top-right-radius: 0rem!important;
}
.tab-content>.active {
    display: block;
    background: white;
    min-height: 200px;
    
    
}
.nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom: 0;
    border-right: 1px solid transparent;
    padding-right: 15px;
}

hr.solid {
    border-top: 3px solid #bbb;
  }
.nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom:0;
    border-right: 1px solid #ddd;
    padding-right: 15px;
    
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background: white;
   
}

.nav-tabs .nav-link.active {
    color: white;
    background-color:#B49C73 !important;
    border-color: transparent !important;
   
    
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0rem!important;
    border-top-right-radius: 0rem!important;
}
.tab-content>.active {
    display: block;
    background: white;
    min-height: 200px;
   
    
    
}
.nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 20px;
    border-bottom: 0;
    border-right: 1px solid transparent;
    padding-right: 15px;
}

hr.solid {
    border-top: 3px solid #bbb;
  }
