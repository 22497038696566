.sticky-container{
    padding:0px;
    margin:0px;
    position:fixed;
    right:-105px;
    top:280px;
    width:210px;
    z-index: 1100;
}
.sticky li{
    list-style-type:none;
    border-radius: 4px;
    background-color: rgb(134, 200, 222);
    color:#efefef;
    height:75px;
    padding:16px;
    margin:0px 0px 1px 0px;
    -webkit-transition:all 0.25s ease-in-out;
    -moz-transition:all 0.25s ease-in-out;
    -o-transition:all 0.25s ease-in-out;
    transition:all 0.25s ease-in-out;
    cursor:pointer;
}
.sticky li:hover{
    margin-left:-85px;
    border-radius: 40px;
 
}
.sticky li p:hover{
    border-left: 2px solid white;
    height:60px;
    position: absolute;
    
    left: 10%;
    margin-left: -2px;
    top: 2px;
  }
.sticky li img{
    float:left;
    margin:5px 4px;
    margin-right:5px;
    background-color:rgb(134, 200, 222);
}
.sticky li p{
    padding-top:28px;
    margin:0px;
    line-height:16px;
    font-size:14px;
}
.sticky li p a{
    text-decoration:none;
    color:white;
}
.sticky li p a:hover{
   
    color:white;
}



